import React, { Component } from 'react';
import { Container, Row, Col, Button, Table, Spinner, Modal } from 'react-bootstrap';
import { Redirect, withRouter } from "react-router-dom";
import Footer from "../../../commons/components/footer/footer";
import Header from "../../../commons/components/header/header";
import AccountNav from "../../../commons/components/header/account-nav";
import AccountService from "../../../commons/services/account-service";
import AdminService from "../../../commons/services/admin-service";
import * as Utils from "../../../commons/utils";
import UserModel from "../../../commons/model/user";
import UserEmail from "../user-emails/user-emails";
import "./search.scss";

class AccountSearch extends Component {
	constructor(props) {
		super(props);
		const user = new UserModel({});
		this.state = {
			completed: false,
			error: null,
			valid: false,
			user,
			processing: false,
			searching: false,
			searchEmail: '',
			searchPhone: '',
			searchTrackingNumber: '',
			showModal: false,
		}
		this.handleSearchChange = this.handleSearchChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.search = this.search.bind(this);
		this.clear = this.clear.bind(this);
		this.revokeUser = this.revokeUser.bind(this);
  }

	saveAndContinue = async (e) => {
		e.preventDefault()
		console.log('saveAndContinue:', this.state.user);
		const errors = Utils.validateUserData(this.state.user);
		this.setState({ processing: true });
		if (errors.length > 0) {
			const errorText = errors.reduce((errorString, error) => ({ error: errorString.error + "\n" + error.error }));
			this.setState({ error: errorText.error, processing: false, });
			return;
		}
		try {
			console.log('about to update user', this.state.user);
			const accountService = new AccountService();
			const user = await accountService.updateUser(
				this.state.user.userId, 
				this.state.user.getDataForUpdate()
			);
			
			if (user.error || user.errors) {
					this.setState({ error: user.error || user.errors, processing: false, });
					return;
			}
			console.log('saved user:', user);
			this.setState({ completed: true, processing: false, error: 'Saved successfully!' });
			} catch (err) {
				console.log(err);
				this.setState({ error: err.message, processing: false, });
			}
	}

	handleSearchChange = (event) => {
		this.setState({ [event.target.id]: event.target.value });
	};

	handleChange = (event) => {
		const user = this.state.user;
		user[event.target.id] = event.target.value ;
		this.setState({ user });
	};

	revokeUser = async (event) => {
		try {
			const { user } = this.state;
			console.log(`about to revoke user ${user.userId}`);
			if (user.userId) {
				this.setState({showModal: true});
			}
		} catch (err) {

		}
	}

	handleModalClose = async (revoke) => {
		try {
			const { user } = this.state;
			console.log(`about to revoke user ${user.userId}, ${revoke}`);
			this.setState({ showModal: false });
			if (user.userId && revoke) {
				console.log(`about to revoke user ${user.userId}`);
				const accountService = new AccountService();
				const userResult = await accountService.revokeUser(
					user.userId, 
					{ email: user.email }
				);
				if (userResult.error || userResult.errors) {
						this.setState({ error: userResult.error || userResult.errors });
						return;
				}
				this.setState({user: {}})
			}
		} catch (err) {

		}
	}

	search = async (event) => {
		try {
			const { searchEmail, searchPhone, searchTrackingNumber } = this.state;
			if (searchEmail || searchPhone || searchTrackingNumber) {
				console.log('about to search');
				const adminService = new AdminService();
				const searchCriteria = {};
				if (searchEmail) searchCriteria.email = searchEmail;
				if (searchPhone) searchCriteria.phone = searchPhone;
				if (searchTrackingNumber) searchCriteria.tracking_number = searchTrackingNumber;
				this.setState({
					completed: false,
					error: null,
					valid: false,
					user: {},
					processing: false,
					searchEmail: '',
					searchPhone: '',
					searchTrackingNumber: '',
					searching: true,
				})
				const user = await adminService.searchUser(searchCriteria);
				console.log('user:', user);
				if (user.error || user.errors) {
						this.setState({ 
							user:  new UserModel({}),
							error: user.error || user.errors,
							searching: false,
						});
						return;
				}
				this.setState({
					user,
					searching: false,
				});
			}
		} catch (err) {
			this.setState({ 
				error: 'Search failed',
				searching: false,
			});
		}
	}

	clear = (event) => {
		this.setState({
			completed: false,
			error: null,
			valid: false,
			user: {},
			processing: false,
			searchEmail: '',
			searchPhone: '',
			searchTrackingNumber: '',
		})
	}

	requestResend = async (e) => {
			try {
				
				if (this.state.user.email) {
					const accountService = new AccountService();
					await accountService.resendEmailVerification(this.state.user.userId, this.state.user.email);
					this.setState({ error: 'Verification email sent.' });
				} 
			} catch (err) {
						this.setState({
							error: 'Request to resend email verfication failed.'
					});
			}
			e.preventDefault();
	};

	requestResendContract = async (e) => {
		try {
			
			if (this.state.user.trackingNumber) {
				const accountService = new AccountService();
				await accountService.resendProxy(this.state.user.userId);
				this.setState({ error: 'Copy of Proxy Agreement sent.' });
			} 
		} catch (err) {
					this.setState({
						error: 'Request to resend email verfication failed.'
				});
		}
		e.preventDefault();
};

	renderModal() {
		const { showModal, user: { userId, firstName, lastName } } = this.state;
		return (
			<>
				<Modal
					show={showModal}
					onHide={this.handleClose}
					backdrop="static"
					keyboard={false}
				>
					<Modal.Header closeButton>
						<Modal.Title>Revoking User</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						You are revking user {userId} - {firstName}, {lastName}, this action will delete the user from our database permanently. Are you sure?
					</Modal.Body>
					<Modal.Footer>
						<Button className="next-button uppercase-text" variant="secondary" onClick={() => this.handleModalClose(false)}>
							NO
						</Button>
						<Button className="next-button uppercase-text" variant="primary" onClick={() => this.handleModalClose(true)}>YES, I'm Sure</Button>
					</Modal.Footer>
				</Modal>
			</>
		);
	}

	renderSearchBar() {
		const { 
			searching,			
			searchEmail,
			searchPhone,
			searchTrackingNumber
		} = this.state;

		return (
			<Container className="container-search-form" fluid>
				<Row className="search-row">
					<Col>
						<div className="col-text-bold">Primary Email</div>
						<input
							type="text" 
							className="col-text col-text-maxwidth" 
							onChange={(e) => this.handleSearchChange(e)} 
							id="searchEmail" 
							value={searchEmail}></input>
					</Col>
					<Col>
						<div className="col-text-bold">Tracking Number</div>
						<input 
							type="text" 
							className="col-text col-text-maxwidth" 
							onChange={(e) => this.handleSearchChange(e)} 
							id="searchTrackingNumber" 
							value={searchTrackingNumber}></input>
					</Col>
				</Row>
				<Row className="search-row">
				<Col>
						<div className="col-text-bold">Mobile Phone</div>
						<input 
							type="tel" 
							className="col-text col-text-maxwidth" 
							onChange={(e) => this.handleSearchChange(e)} 
							id="searchPhone" 
							value={searchPhone}></input>
					</Col>
					<Col>
						{ !searching && 
							<Button
								className="next-button uppercase-text"
								onClick={this.search}
							>
								Search
							</Button>	
						}
						{ searching &&
							<Spinner animation="grow" variant="success" />
						}
						<Button
							className="next-button uppercase-text"
							onClick={this.clear}
						>
							Clear
						</Button>	
					</Col>
				</Row>
				</Container>
		)
	}

	renderProxySection() {
		const { proxyAuthorization, signedAt, trackingNumber, referralCode, referralCount } = this.state.user;
		return (
			<React.Fragment>
				{
					(proxyAuthorization === 'accepted' || proxyAuthorization === 'revoked') &&  (
						<tr className="intro-row">
							<td className="intro-col flex-col">
								<div className="col-text-bold">Proxy Status</div>
								<input 
									type="text" 
									className="col-text col-text-maxwidth"  
									id="proxyAuthorization" 
									disabled 
									readOnly 
									value={proxyAuthorization === 'accepted' ? 'SIGNED' : 'REVOKED'}
								/>
								<input 
									type="text" 
									className="col-text col-text-maxwidth"  
									id="signedAt" 
									disabled 
									readOnly 
									value={proxyAuthorization === 'accepted' ? `Signed Date: ${signedAt}` : ''}
								/>
							</td>
						</tr>
					)
				}
				{
					proxyAuthorization === '' &&  (
						<tr className="intro-row">
							<td className="intro-col flex-col">
								<div className="col-text-bold">Proxy Status</div>
								<input 
									type="text" 
									className="col-text col-text-maxwidth"  
									id="proxyAuthorization" 
									disabled 
									readOnly 
									value={proxyAuthorization === 'accepted' ? 'SIGNED' : 'NOT SIGNED'}
								/>
							</td>
						</tr>
					)
				}
				{
					trackingNumber &&  (
						<React.Fragment>
							<tr className="intro-row">
								<td className="intro-col flex-col">
									<div className="col-text-bold">Tracking Number</div>
									<input 
										type="text" 
										className="col-text col-text-maxwidth"  
										id="trackingNumber" 
										disabled 
										readOnly 
										value={trackingNumber}
									/>
								</td>
							</tr>
							<tr className="intro-row">
								<td className="intro-col">
									<Button
												className="resend-button"
												onClick={this.requestResendContract}
										>
												Email another copy of my Authorization/Proxy Agreement
									</Button>	
								</td>
							</tr>
							<tr className="intro-row">
								<td className="intro-col">
									<div className="col-text-bold">Referral Link</div>
									<div className="form-check-blue-label">You cannot edit your DDP Referral Link</div>
									<a
										className="col-text col-text-maxwidth"
										id="referralCode"
										href={`${process.env.REACT_APP_WEB_URL}signup?ref=${referralCode}`}>{`${process.env.REACT_APP_WEB_URL}signup?ref=${referralCode}`}</a>
								</td>
							</tr>
							<tr className="intro-row">
								<td className="intro-col">
									<div className="col-text-bold">Total Referrals to date</div>
									<div className="form-check-blue-label">You cannot edit your total Referrals</div>
									<input
										type="text"
										className="col-text col-text-maxwidth"
										id="referralCount"
										disabled
										readOnly
										value={referralCount}></input>
								</td>
							</tr>
						</React.Fragment>
					)
				}
			</React.Fragment>
		)
	}

	renderUser() {
		const { 
			user: {
				userId, firstName, lastName, email, phone, street, city, state, zipCode, yob, emailVerified, createdAt, marketingEmails,
			},
			processing,
		} = this.state;
		return (
			<React.Fragment>
				<Table className="user-details-table" responsive>
					<thead>
							<tr>
								<th className="intro-heading">User Details
								</th>
							</tr>
					</thead>
					<tbody>
						{ this.renderProxySection() }
						<tr className="intro-row">
							<td className="intro-col flex-col">
								<div className="col-text-bold">Mobile Phone</div>
								<input 
										type="tel" 
										maxLength="10" 
										className="col-text col-text-maxwidth" 
										onChange={(e) => this.handleChange(e)} 
										id="phone" value={phone} 
										placeholder="10-Digit Mobile Phone Number"
										pattern="\d{10}" 
										readOnly
										disabled
									/>
									<input 
										type="tel" 
										maxLength="10" 
										className="col-text col-text-maxwidth" 
										onChange={(e) => this.handleChange(e)} 
										id="createdAt"
										value={`Account Created Date: ${createdAt}`} 
										placeholder="Account Created Date"
										readOnly
										disabled
									/>
							</td>
						</tr>
						<tr className="intro-row">
							<td className="intro-col">
									<div className="col-text-bold">Email</div>
									<input type="text" className="col-text col-text-maxwidth" onChange={(e) => this.handleChange(e)} id="email" value={email}></input>
							</td>
						</tr>
						{
							emailVerified === 'N' &&  (
								<tr className="intro-row">
									<td className="intro-col">
										<Button
													className="resend-button"
													onClick={this.requestResend}
											>
													Resend Email Verification
										</Button>	
									</td>
								</tr>
							)
						}
						<tr className="intro-row">
							<td className="intro-col">
								<div className="col-text-bold">First Name</div>
								<input type="text" className="col-text uppercase-text col-text-maxwidth" onChange={(e) => this.handleChange(e)} id="firstName" value={firstName}></input>
							</td>
						</tr>
						<tr className="intro-row">
							<td className="intro-col">
								<div className="col-text-bold">Last Name</div>
								<input type="text" className="col-text uppercase-text col-text-maxwidth" onChange={(e) => this.handleChange(e)} id="lastName" value={lastName}></input>
							</td>
						</tr>
						<tr className="intro-row">
							<td className="intro-col">
								<div className="col-text-bold">Street</div>
								<input type="text" className="col-text uppercase-text col-text-maxwidth" onChange={(e) => this.handleChange(e)} id="street" value={street}></input>
							</td>
						</tr>
						<tr className="intro-row">
							<td className="intro-col">
								<div className="col-text-bold">City</div>
								<input type="text" className="col-text uppercase-text col-text-maxwidth" onChange={(e) => this.handleChange(e)} id="city" value={city}></input>
							</td>
						</tr>
						<tr className="intro-row">
							<td className="intro-col">
								<div className="col-text-bold">State</div>
								<input type="text" className="col-text uppercase-text col-text-maxwidth" onChange={(e) => this.handleChange(e)} id="state" value={state}></input>
							</td>
						</tr>
						<tr className="intro-row">
							<td className="intro-col">
								<div className="col-text-bold">Zip Code</div>
								<input
									type="text"
									className="col-text col-text-maxwidth" 
									placeholder="Enter Zip Code"
									onChange={(e) => this.handleChange(e)}
									maxLength="5"
									required
									autoFocus
									id="zipCode" 
									value={zipCode}
									pattern="[0-9][0-9][0-9][0-9][0-9]" 
								/>
							</td>
						</tr>
						<tr className="intro-row">
								<td className="intro-col">
										<div className="col-text-bold">Date of Birth (MM/DD/YYYY)</div>
										<input 
											type="text" 
											className="col-text col-text-maxwidth" 
											maxLength="10" onChange={(e) => this.handleChange(e)} 
											id="yob" 
											value={yob} 
											placeholder="MM/DD/YYYY"
											pattern="[0-1][0-2][/-][0-3][0-9][/-][1-2]\d{3}" 
										/>
									</td>
						</tr>
						<tr className="intro-row">
								<td className="intro-col">
										<div className="col-text-bold">
											Marketing Emails
										</div>
										<input 
											type="checkbox" 
											onChange={(e) => this.handleChange(e)} 
											id="marketingEmails" 
											name="marketingEmails"
											value={marketingEmails}  
											checked={marketingEmails}
											disabled
											readOnly
										/>&nbsp;Subscribe to Marketing Emails (Non Editable)
									</td>
						</tr>
						{this.state.error && (
							<tr className="intro-row">
								<td className="intro-col">
										<span className="error-text">{this.state.error}</span>
								</td>
							</tr>
						)}
						<tr className="intro-row">
							<td className="intro-col">
								{(!processing && phone) &&
									<Button
											className="next-button uppercase-text"
											onClick={this.saveAndContinue}
									>
											SAVE
									</Button>
								}
								{processing &&
									<Spinner animation="grow" variant="success" />
								}
								{ (!processing && phone) && 
									<Button
										className="next-button uppercase-text"
										onClick={this.revokeUser}
									>
										Revoke
									</Button>
								}
							</td>
						</tr>   
					</tbody>
				</Table>
				{(!processing && userId) &&
					<UserEmail userId={userId} />
				}
			</React.Fragment>
			
		)
	}

 	render() {
		if (!Utils.getAuthToken() || this.state.error === 'Invalid token.') {
			return (<Redirect to="/signin" />);
		}

		return (
			<Container className="container-search" fluid>
				<Header />
				<AccountNav />
				{ this.renderSearchBar() }
				{ this.state.user.userId && this.renderUser() }
				{ !this.state.user.userId && 
						<Table className="user-details-table">
							<tbody>
									{this.state.error && (
										<tr className="intro-row">
											<td className="intro-col">
													<span className="error-text">{this.state.error}</span>
											</td>
										</tr>
									)}
									{!this.state.error && (
										<tr>
											<td />
										</tr>
									)}
							</tbody>
						</Table> 
				}
				{this.renderModal()}
				<AccountNav />
				<Footer />
			</Container>
		);
	}
}

export default withRouter(AccountSearch);