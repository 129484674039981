import React from "react";
import { Container, Nav, Row, Col } from "react-bootstrap";
import "./footer.css";

const Footer = () => {
  
  return (
        <Container className="container-footer" fluid>
          <Nav className="justify-content-center">
                <Nav.Item className="row-footer">
                    <Nav.Link className="a-footer" href="/">HOME</Nav.Link>
                </Nav.Item>
                <Nav.Item className="row-footer">
                    <Nav.Link className="a-footer" href="https://blog.datadividendproject.com">BLOG</Nav.Link>
                </Nav.Item>
            </Nav>
            <Row className="row-footer">
                <Col className="col-footer">
                    &copy; The Data Dividend Project 2020
                </Col>
            </Row>
      </Container>
  );
};

export default Footer;
