import UserModel from "../model/user";
import UserEmailModel from "../model/user-email";
import makeRest from "./rest.js";

export default class AccountService {
	async updateUser(userId, user) {
		try {
			const requestOptions = makeRest('POST', true, user);
			const response = await fetch(
				`${process.env.REACT_APP_API_SERVER}user/${userId}`,
				requestOptions
			);
			const data = await response.json();
			const userModel = new UserModel(data);
			return userModel;
		} catch (err) {
			console.log(err);
			const user = new UserModel({error: 'User Data update failed.'});;
			return user;
		}
	}
	
	async revokeUser(userId, user) {
		try {
			const requestOptions = makeRest('POST', true, user);
			const response = await fetch(
				`${process.env.REACT_APP_API_SERVER}user/${userId}/revoke`,
				requestOptions
			);
			const data = await response.json();
			const userModel = new UserModel(data);
			return userModel;
		} catch (err) {
			console.log(err);
			const user = new UserModel({error: 'User Data update failed.'});;
			return user;
		}
  }

	async resendEmailVerification(userId, email) {
		try {
			console.log("called resendMobile:", email);
			const requestOptions = makeRest('POST', true, {
				email: email,
				user_id: userId, 
			});
			const response = await fetch(
					`${process.env.REACT_APP_API_SERVER}user/${userId}/email/verify`,
					requestOptions
				);
			const data = await response.json();
			if (data.error) {
				throw new Error(data.error);
			}
			return data;
		} catch (err) {
			console.log(err);
			const user = {};
			user.error = 'SMS code request failed';
			return user;
		}
  }

	async getUserEmails(userId) {
		try {
			const requestOptions = makeRest('GET', true);
			const response = await fetch(
				`${process.env.REACT_APP_API_SERVER}user/${userId}/email`,
				requestOptions
			);
			const data = await response.json();
			const userEmailModel = new UserEmailModel(data);
			return userEmailModel;
		} catch (err) {
			console.log(err);
			const userEmailModel = new UserEmailModel({error: 'Get User Emails fetch failed.'});;
			return userEmailModel;
		}
  }

	async addEmails(userId, emails) {
		try {
			console.log("called addEmails:", emails);
			const requestOptions = makeRest('POST', true, {
				emails: emails,
			});
			const response = await fetch(
					`${process.env.REACT_APP_API_SERVER}user/${userId}/email`,
					requestOptions
				);
			const data = await response.json();
			if (data.error || data.errors) {
				throw new Error(data.error || data.errors);
			}
			return data;
		} catch (err) {
			console.log(err);  
			throw err;
		}
  }

	async deleteEmails(userId, emails) {
		try {
			console.log("called addEmails:", emails);
			const requestOptions = makeRest('POST', true, {
				emails: emails,
			});
			const response = await fetch(
					`${process.env.REACT_APP_API_SERVER}user/${userId}/email/delete`,
					requestOptions
				);
			const data = await response.json();
			if (data.error || data.errors) {
				throw new Error(data.error || data.errors);
			}
			return data;
		} catch (err) {
			console.log(err);  
			throw err;
		}
	}
	
	async resendProxy(userId) {
		try {
			const requestOptions = makeRest('GET', true);
			const response = await fetch(
				`${process.env.REACT_APP_API_SERVER}user/${userId}/proxy`,
				requestOptions
			);
			const data = await response.json();
			const userModel = new UserModel(data);
			return userModel;
		} catch (err) {
			console.log(err);
			const userModel = new UserModel({error: 'Request to resend proxy failed.'});;
			return userModel;
		}
  }
}