import React from "react";
import {  Nav } from "react-bootstrap";
import * as Utils from "../../utils";

const AccountNav = () => {
    function signout() {
        Utils.clearSessionStorage();
        window.location.href ="/signin";
    }

    return (
			<Nav
				 defaultActiveKey="/search"
				 bg="dark"
				 className="justify-content-center container-faccount-nav"
			>
			{
				Utils.getAuthToken()  && (
					<React.Fragment>
						<Nav.Item className="row-footer">
							<Nav.Link className="a-header-nav" href="/search">Search</Nav.Link>
						</Nav.Item>
						<Nav.Item className="row-footer">
							<Nav.Link className="a-header-nav" eventKey="signout" onClick={signout}>Sign Out</Nav.Link>
						</Nav.Item>
					</React.Fragment>
				)
			}
			</Nav>
    );
};

export default AccountNav;
