import EmailModel from "./email";

export default class UserEmailModel {
	constructor(data) {
		this._userId = data.user_id || '';
		this._emails = [];
		if (data.emails) {
			data.emails.forEach(email => this._emails.push(new EmailModel(email)));
		}
		this._error = data.error || '';
	}

	get userId() {
		return this._userId;
	}

	get emails() {
		return this._emails;
	}

	get error() {
		return this._error;
	}

	getDataForUpdate() {
		const email_array = [];
		this._emails.forEach(email => {
			//locally added emails won't have email_verified field in the object
			if (email.emailVerified  === '') {
				email_array.push({email: email.email})
			}				
		});
		return email_array;
	}

	getDataForDelete() {
		const email_array = [];
		this._emails.forEach(email => {
			//locally added emails won't have email_verified field in the object
			if (email.delete) {
				email_array.push({email: email.email})
			}			
		});
		return email_array;
	}

	addEmail(email) {
		this._emails.push(new EmailModel({ email }));
	}

	deleteEmail(emailtoDelete) {
		console.log(this._emails);
		this._emails.find(email => email.email === emailtoDelete).delete = true;
		console.log(this._emails);
	}
}