import React from "react";
import { Navbar } from "react-bootstrap";
import * as Utils from "../../utils";
import "./header.css";

const Header = () => {
	const user = Utils.getAdminUserFromSession();
	return (
		<Navbar bg="light">
			<Navbar.Brand href="/" className="nav-brand">
			</Navbar.Brand>
			<Navbar.Toggle />
			<Navbar.Collapse className="justify-content-center signin-title">
				<Navbar.Text>
					Admin Tool
				</Navbar.Text>
			</Navbar.Collapse>
			<Navbar.Toggle />
			{
				user && (
					<Navbar.Collapse className="justify-content-end">
						<Navbar.Text>
						Signed in as: {user.userName}
						</Navbar.Text>
					</Navbar.Collapse>
				)
			}
		</Navbar>
	);
};

export default Header;
