
import moment from 'moment';

export default class UserModel {
	constructor(data) {
		this._userId = data.user_id || '';
		this._firstName = data.first_name || '';
		this._lastName = data.last_name || '';
		this._authToken = data.auth_token || '';
		this._street = data.street || '';
		this._city = data.city || '';
		this._state = data.state || '';
		this._phone = data.phone || '';
		this._proxyAuthorization = data.proxy_authorization || '';
		this._trackingNumber = data.tracking_number || '';
		this._yob = data.date_of_birth ? moment(data.date_of_birth).format("MM/DD/YYYY") : '';
		this._emailVerified = data.email_verified || '';
		this._error = data.error || '';
		this._email = data.email || '';
		this._zipCode = data.zip_code || '';
		this._ddpSupported = data.ddp_supported || '';
		this._signedAt = data.signed_at ? moment(data.signed_at).format("MM/DD/YYYY") : '';
		this._createdAt = data.created_at ? moment(data.created_at).format("MM/DD/YYYY") : '';
		this._marketingEmails = data.marketing_emails || '';
		this._referralCode = data.referral_code || '';
		this._referralCount = data.referral_count || 0;
	}

	get userId() {
		return this._userId;
	}

	get firstName() {
		return this._firstName;
	}

	get lastName() {
		return this._lastName;
	}

	get phone() {
		return this._phone;
	}

	get email() {
		return this._email;
	}

	get emailVerified() {
		return this._emailVerified;
	}

	get street() {
		return this._street;
	}

	get city() {
		return this._city;
	}

	get state() {
		return this._state;
	}

	get zipCode() {
		return this._zipCode;
	}

	get authToken() {
		return this._authToken;
	}

	get trackingNumber() {
		return this._trackingNumber;
	}

	get yob() {
		return this._yob;
	}

	get proxyAuthorization() {
		return this._proxyAuthorization;
	}

	get ddpSupported() {
		return this._ddpSupported;
	}

	get marketingEmails() {
		return this._marketingEmails;
	}

	get signedAt() {
		return this._signedAt;
	}

	get createdAt() {
		return this._createdAt;
	}

	get referralCode() {
		return this._referralCode;
	}

	get referralCount() {
		return this._referralCount;
	}

	get error() {
		return this._error;
	}

	getDataForUpdate() {
		return {
			email: this.email,
			first_name: this.firstName,
			last_name: this.lastName,
			street1: this.street,
			state: this.state,
			city: this.city,
			zip_code: this.zipCode,
			date_of_birth: this.yob
		}
	}

	set firstName(value) {
		this._firstName = value;
	}

	set lastName(value) {
		this._lastName = value;
	}

	set email(value) {
		this._email = value;
	}

	set phone(value) {
		this._phone = value;
	}

	set street(value) {
		this._street = value;
	}

	set state(value) {
		this._state = value;
	}

	set city(value) {
		this._city = value;
	}

	set yob(value) {
		this._yob = value;
	}

	set zipCode(value) {
		this._zipCode = value;
	}

	set marketingEmails(value) {
		this._marketingEmails = value;
	}
}