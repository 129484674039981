import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Container, Form, Button } from "react-bootstrap";
import Footer from "../../commons/components/footer/footer";
import Header from "../../commons/components/header/header";
import AdminService from "../../commons/services/admin-service";
import * as Utils from "../../commons/utils";
import "./sign-in.scss";

class SignIn extends Component {
	constructor(props) {
		super(props);
		this.state = {
				userName: '',
				password: '',
				completed: false,
				errorText: '',
				user: {},
				smsCode: '',
		};
		this.handleUserNameChange = this.handleUserNameChange.bind(this);
		this.handlePasswordChange = this.handlePasswordChange.bind(this);
		this.loginUser = this.loginUser.bind(this);
		this.loginUser2FA = this.loginUser2FA.bind(this);
	}

	handleUserNameChange = async (event) => {
		this.setState({ userName: event.target.value });
	};

	handlePasswordChange = (event) => {
		this.setState({ password: event.target.value });
	};

	handleCodeChange = (event) => {
		this.setState({ smsCode: event.target.value });
	};

	loginUser = async (e) => {
		try {		
			Utils.clearSessionStorage();
			const { userName, password } = this.state;
			if (!userName || (userName && userName.length < 5)) {
				this.setState({
					errorText: 'Please enter user name.',
				});
				return;
			}
			if (!password || (password && password.length < 8) ) {
				this.setState({
					errorText: 'Please enter password.',
				});
				return;
			}
			const adminService = new AdminService();
			const user = await adminService.login({
					user_name: userName,
					password,
			});
			console.log("loginUser user:", user);
			if (user.error || !user.userName) {
						this.setState({
							completed: false,
							errorText: 'Login failed.',
					});
					e.preventDefault();
					return;
			} 
			// Utils.setAdminUserInSession(user);
			this.setState({ user, errorText: '' });
		} catch (err) {
					this.setState({
						completed: false,
						confirmationCode: '',
						errorText: 'Please enter valid login details.'
				});
		}
		e.preventDefault();
	};

	loginUser2FA = async (e) => {
		try {		
			Utils.clearSessionStorage();
			const { userName, smsCode } = this.state;
	
			if (!smsCode || (smsCode && smsCode.length < 6) ) {
				this.setState({
					errorText: 'Please enter SMS Code.',
				});
				return;
			}
			const adminService = new AdminService();
			const user = await adminService.login2FA({
					user_name: userName,
					code: smsCode,
			});
			console.log("loginUser2FA user:", user);
			if (user.error || !user.userName) {
						this.setState({
							completed: false,
							errorText: 'Login failed.',
					});
					e.preventDefault();
					return;
			} 
			Utils.setAdminUserInSession(user);
			this.setState({ completed: true, errorText: '' });
			adminService.setSessionTimeout();
		} catch (err) {
					this.setState({
						completed: false,
						confirmationCode: '',
						errorText: 'Please enter valid login details.'
				});
		}
		e.preventDefault();
	};

	render() {
			const { userName, password , user , smsCode} = this.state;

			if (this.state.completed) {
					return <Redirect to="/search" />;
			}
			return (
					<Container className="container-signin" fluid>
							<Header />
							<div className="container-signin-form">
								<Form  className="signin-form" onSubmit={this.saveAndContinue}>
										<Form.Group
												controlId="formUserName"
												className="form-group-signin justifiy-content-center"
										>
											<Form.Label className="signin-title">Sign-In</Form.Label>
											<Form.Label className="form-check-black-label">This site usage is restricted/intended to use by DDP employees and consultants.</Form.Label>
										</Form.Group>
										<Form.Group
												controlId="formUserName"
												className="form-group-signin  col-text-maxwidth"
										>
											<Form.Label className="col-text-bold">User Name</Form.Label>
										</Form.Group>
										<Form.Group
												className="form-group-signin  col-text-maxwidth"
										>
											<Form.Control
												className="col-text col-text-maxwidth"
												type="text"
												placeholder="User Name"
												onChange={(e) => this.handleUserNameChange(e)}
												maxLength="25"
												required
												autoFocus
												defaultValue={userName}
											/>
										</Form.Group>
										<Form.Group
												controlId="formBasicPassword"
												className="form-group-signin  col-text-maxwidth"
										>
										<Form.Label className="col-text-bold">Password</Form.Label>
										</Form.Group>
										<Form.Group
												className="form-group-signin  col-text-maxwidth"
										>
											<Form.Control
												className="col-text col-text-maxwidth"
												type="password"
												placeholder="Password"
												onChange={(e) => this.handlePasswordChange(e)}
												maxLength="20"
												required
												value={password}
											/>
											{this.state.errorText && (
												<Form.Text className="error-text">
														{this.state.errorText}
												</Form.Text>
											)}
										</Form.Group>
										<Form.Group
												className="form-group-signin col-text-maxwidth"
										>
											<Button
												className="signin-button col-text-maxwidth"
												onClick={this.loginUser}
											>
												SIGN-IN
											</Button>
											</Form.Group>
										{ (user && user.status === '2FA_VERIFY') && (
												<React.Fragment>
													<Form.Group
															controlId="formBasic2FA"
															className="form-group-signin  col-text-maxwidth"
													>
													<Form.Label className="col-text-bold">2FA</Form.Label>
													<Form.Label className="col-text">We sent code to phone on file {user.phone}</Form.Label>
													</Form.Group>
													<Form.Group
															className="form-group-signin  col-text-maxwidth"
													>
														<Form.Control
															className="col-text col-text-maxwidth"
															type="number"
															placeholder="Verification code"
															onChange={(e) => this.handleCodeChange(e)}
															maxLength="6"
															required
															value={smsCode}
														/>
														{this.state.errorText && (
															<Form.Text className="error-text">
																	{this.state.errorText}
															</Form.Text>
														)}
													</Form.Group>
													<Form.Group
															className="form-group-signin col-text-maxwidth"
													>
														<Button
															className="signin-button col-text-maxwidth"
															onClick={this.loginUser2FA}
														>
															Finish sign-in
														</Button>
													</Form.Group>
												</React.Fragment>
										)}
								</Form>
							</div>
							<Footer />
					</Container>
			);
	}
}

export default  withRouter(SignIn);
