export default class AdminUserModel {
	constructor(data) {
		this._userName = data.user_name|| '';
		this._firstName = data.first_name || '';
		this._lastName = data.last_name || '';
		this._authToken = data.auth_token || '';
		this._role = data.role || '';
		this._status = data.status || '';
		this._phone = data.phone || '';
	}

	get userName() {
		return this._userName;
	}

	get firstName() {
		return this._firstName;
	}

	get lastName() {
		return this._lastName;
	}

	get phone() {
		return this._phone;
	}

	get role() {
	return this._role;
	}
	
	get authToken() {
	return this._authToken;
	}
		
	get status() {
		return this._status;
	}

	get error() {
		return this._error;
	}
}