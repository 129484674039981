import React from 'react';
import {
  Route,
  Switch,
  BrowserRouter,
} from "react-router-dom";
import Signin from "./pages/sign-in/sign-in";
import AccountSearch from "./pages/account/search/search";
import UserEmails from "./pages/account/user-emails/user-emails";

function App() {
  return (
		<div className="App">
			<BrowserRouter>
				<Switch>
					<Route path="/search">		
 						<AccountSearch />		
 					</Route> 
					<Route path="/emails">		
 						<UserEmails />		
 					</Route>
 					<Route path="/">		 				
 						<Signin />	
 					</Route>
				</Switch>
			</BrowserRouter>
		</div>
  );
}

export default App;
