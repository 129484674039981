import React, { Component } from 'react';
import { Container, Button, Table, Spinner } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import AccountService from "../../../commons/services/account-service";
import * as Utils from "../../../commons/utils";
import UserEmailModel from "../../../commons/model/user-email";
import "./user-email.scss";

class UserEmails extends Component {
	constructor(props) {
		super(props);
		const userEmails = new UserEmailModel({});
		this.state = {
			completed: false,
			error: null,
			valid: false,
			userEmails,
			newEmail: '',
			processing: false,
		}
	}
	
	getUserId = () => {
		// const search = this.props.location.search;
		// const params = new URLSearchParams(search);
		// return params.get('userId');	
		return this.props.userId;
	}

	refreshData = async () => {
		this.setState({
			userEmails: {},
			processing: true,
		})
		const accountService = new AccountService();
		const userEmails = await accountService.getUserEmails(this.getUserId());
		if (userEmails.error || userEmails.errors) {
				this.setState({ error: userEmails.error || userEmails.errors, processing: false,  });
				return;
		}
		this.setState({
			userEmails,
			newEmail: '',
			processing: false,
		})
	}

	addEmail = async (e) => {
		try {
			if (this.state.newEmail) {
				this.setState({ processing: true });
				const accountService = new AccountService();
				const { userEmails } = this.state;
				userEmails.addEmail( this.state.newEmail);
				await accountService.addEmails(this.getUserId(), userEmails.getDataForUpdate());
				await this.refreshData();
			} 
		} catch (err) {
					this.setState({
						error: 'Add new email failed.',
						processing: false,
				});
		}
		e.preventDefault();
	}

	deleteEmail = async (e) => {
		try {
			console.log('deleteEmail:', e.target, e.target.attributes['data-id'].value);
			if (e.target.attributes['data-id'].value) {
				const { userEmails } = this.state;
				userEmails.deleteEmail(e.target.attributes['data-id'].value);
				const accountService = new AccountService();
				await accountService.deleteEmails(this.getUserId(), userEmails.getDataForDelete());
				await this.refreshData();
			} 
		} catch (err) {
					this.setState({
						error: 'Request to delete email failed.'
				});
		}
		e.preventDefault();
	}

	requestResend = async (e) => {
		try {
			console.log('request resend:', e.target, e.target.attributes['data-id'].value);
			if (e.target.attributes['data-id'].value) {
				const accountService = new AccountService();
				await accountService.resendEmailVerification(this.getUserId(), e.target.attributes['data-id'].value);
				this.setState({ error: 'Verification email sent.' });
			} 
		} catch (err) {
					this.setState({
						error: 'Request to resend email verfication failed.'
				});
		}
		e.preventDefault();
	};
	

	async componentDidMount() {
		await this.refreshData();
	}

	handleNewEmailChange = (event) => {
		this.setState({ newEmail: event.target.value });
	};

	render() {
		if (!Utils.getAuthToken()) {
			return (<Redirect to="/signin" />);
		}
		
		const { 
			userEmails: {
				emails
			},
			newEmail,
			processing,
		} = this.state;
		console.log(emails);
		return (
			<Container className="container-email" fluid>
				<div className="email-heading">User Primary And Additional Emails</div>
				<Table className="email-table" responsive>
					<thead>
							<tr>
									<th className="email-col-text-bold email-col-1">Email
									</th>
									<th className="email-col-text-bold email-col-2">Primary
									</th>
									<th className="email-col-text-bold email-col-2">Verified
									</th>
									<th className="email-col-text-bold email-col-2">{' '}
									</th>
							</tr>
					</thead>
					<tbody>
						{	(emails && emails.length > 0) && emails.map(email => (
								<tr className="intro-row" key={`${email.email}-emailrow`}>
									<td className="email-col-1 col-text-maxwidth" key={`${email.email}-emailrcol1`}>
										<div className="email-col-text-div " key={`${email.email}-email`} id={email.email}>{email.email}</div>	
									</td>
									<td className="email-col-2 col-text-maxwidth" key={`${email.email}-emailrcolPrimary`}>
										<div className="email-col-text-div " key={`${email.email}-primary`} id={`${email.email}-primary`}>{email.emailPrimary}</div>	
									</td>
									<td className="email-col col-text-maxwidth" key={`${email.email}-emailrcol2`}>
										{
											email.emailVerified === 'Y' && (
												<div className="email-col-text-div " key={`${email.email}-verify`} id={`${email.email}-verfiy`}>Verified</div>
											)
										}
										{
											email.emailVerified === 'N' && (
												<Button
													className="resend-button"
													onClick={this.requestResend}
													key={`${email.email}-verify`} 
													data-id={email.email} 
													id={`${email.email}-resend`}
												>
														Resend
											</Button>		
											)
										}	
									</td>
									<td className="email-col col-text-maxwidth" key={`${email.email}-emailrcol3`}>
										{
											email.emailPrimary === 'N' && (
												<Button
														className="resend-button"
														onClick={this.deleteEmail}
														key={`${email.email}-delete`} 
														data-id={email.email} 
														id={`${email.email}-delete`}
												>
														Delete
												</Button>	
											)
										}
									</td>
								</tr>
							))
						}
						<tr className="intro-row">
							<td className="email-col">
									<input 
										type="text" 
										className="email-input" 
										onChange={(e) => this.handleNewEmailChange(e)} 
										id="email" 
										value={newEmail}
										placeholder="Enter email"
									/>
							</td>
							<td className="email-col">
								{!processing &&
									<Button
										className="email-button"
										onClick={this.addEmail}
										id={`addEmail`}
										disabled={newEmail.length < 5}
									>
											Add
									</Button>	
								}
								{processing &&
									<Spinner animation="grow" variant="success" />
								}
							</td>
						</tr>
					</tbody>
				</Table>
			</Container>
		);
	}
}

export default UserEmails;