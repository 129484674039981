import UserModel from "../model/user";
import AdminUserModel from "../model/admin-user";
import makeRest from "./rest.js";
import * as Utils from "../utils";

export default class AdminService {
	async searchUser(searchCriteria) {
		try {
			console.log("called searchUser", searchCriteria);
			const requestOptions = makeRest('POST', true, searchCriteria);
			const response = await fetch(
					`${process.env.REACT_APP_API_SERVER}admin/search`,
					requestOptions
				);
			const data = await response.json();
			if (data.error) {
				const user = new UserModel({error: data.error});
				return user;
			}
			const user = new UserModel(data);
			return user;
		} catch (err) {
			console.log(err);
			const user = new UserModel({error: 'User Search failed.'});;
			return user;
		}
  }

	async login(login) {
		try {
			const requestOptions = makeRest('POST', false, login);
			const response = await fetch(
				`${process.env.REACT_APP_API_SERVER}admin/login`,
				requestOptions
			);
			const data = await response.json();
			const adminUserModel = new AdminUserModel(data);
			return adminUserModel;
		} catch (err) {
			console.log(err);
			const user = new AdminUserModel({error: 'Login failed.'});;
			return user;
		}
	}
	
	async login2FA(login) {
		try {
			const requestOptions = makeRest('POST', false, login);
			const response = await fetch(
				`${process.env.REACT_APP_API_SERVER}admin/login2FA`,
				requestOptions
			);
			const data = await response.json();
			const adminUserModel = new AdminUserModel(data);
			return adminUserModel;
		} catch (err) {
			console.log(err);
			const user = new AdminUserModel({error: 'Login 2FA failed.'});;
			return user;
		}
	}
	
	setSessionTimeout() {
		window.setTimeout(() => {
			Utils.resetSessionStorage();
			window.location.href ="/signin";
		}, 20 * 60 * 1000);	
	}

}